import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
import { LoaderService } from '../../../core/services/loader.service';
import {
  createpayloadConfig,
  updatepayloadConfig,
} from '../../../store/payload_Config/payloadConfig.actions';

@Component({
  selector: 'app-iot-payload-configuration',
  templateUrl: './iot-payload-configuration.component.html',
  styleUrl: './iot-payload-configuration.component.scss',
})
export class IotPayloadConfigurationComponent {
  isAllOpen: boolean = true;
  payloadConfigForum!: UntypedFormGroup;
  @Input() payloadConfigData: any;
  submitted: boolean = false;
  payloadConfig_Validations = Validations.payloadConfig;
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder // public activeModal: NgbActiveModal
  ) {
    this.getStoreInfo();
    console.log(history.state.payloadConfigData);
  }
  getStoreInfo() {}
  ngOnInit(): void {
    this.payloadConfigForum = this.formBuilder.group({
      payloadName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      parameterName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      positionFrom: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[0-9]*$/),
        ],
      ],
      positionTo: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[0-9]*$/),
        ],
      ],
      dataType: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
    });
    if (history.state.payloadConfigData) {
      this.payloadConfigData = JSON.parse(history.state.payloadConfigData);
      this.payloadConfigForum.patchValue({
        payloadName: this.payloadConfigData.payloadName,
        parameterName: this.payloadConfigData.parameterName,
        positionFrom: this.payloadConfigData.positionFrom,
        positionTo: this.payloadConfigData.positionTo,
        dataType: this.payloadConfigData.dataType,
      });
    }
  }

  SavePayloadConfig(type: string): void {
    this.submitted = true;
    if (this.payloadConfigForum.valid) {
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.payloadConfigForum.value);
      if (type == 'Create') {
        this.store.dispatch(
          createpayloadConfig({
            payloadConfigData: this.payloadConfigForum.value,
          })
        );
      } else {
        this.store.dispatch(
          updatepayloadConfig({
            payloadConfigData: this.payloadConfigForum.value,
            id: this.payloadConfigData.id,
          })
        );
      }
      // else if (type == 'Update') {
      //   console.log(this.payloadConfigForum.value);
      //   this.store.dispatch(
      //     updateMaster({
      //       masterData: this.payloadConfigForum.value,
      //       id: this.masterData.id,
      //     })
      //   );
      // }
    }
  }
}
