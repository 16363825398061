import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IotConfigurationViewComponent } from './iot-configuration-view/iot-configuration-view.component';
import { IotCreateResponseConfigurationComponent } from './iot-create-response-configuration/iot-create-response-configuration.component';
import { IotPayloadConfigurationComponent } from './iot-payload-configuration/iot-payload-configuration.component';

const routes: Routes = [
  { path: 'configuration', component: IotConfigurationViewComponent },
  { path: 'configuration', component: IotConfigurationViewComponent },
  {
    path: 'configuration/createResponseConfiguration',
    component: IotCreateResponseConfigurationComponent,
  },
  {
    path: 'configuration/payloadConfiguration',
    component: IotPayloadConfigurationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
