import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  process,
  State,
} from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import {
  createpayloadConfig,
  getAllpayloadConfig,
  updatepayloadConfig,
} from '../../../store/payload_Config/payloadConfig.actions';
import { Store } from '@ngrx/store';
import { PayloadConfigService } from '../../../core/services/payloadConfig.services';
import {
  ConfirmationModal,
  ResponseConfiguration,
  table_pageSize,
} from '../../../shared/modals/common';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { LoaderService } from '../../../core/services/loader.service';
import { KeyMapping } from '../../../shared/modals/common';

@Component({
  selector: 'app-iot-create-response-configuration',
  templateUrl: './iot-create-response-configuration.component.html',
  styleUrls: ['./iot-create-response-configuration.component.scss'],
})
export class IotCreateResponseConfigurationComponent implements OnInit {
  public sort: SortDescriptor[] = [];
  public pageSize = 10;
  public skip = 0;
  public totalKeysMappingCount = 0;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public keysMappedData: GridDataResult = { data: [], total: 0 };
  public payloadGridData: GridDataResult = { data: [], total: 0 };
  public keysmappingList: any[] = [];

  @ViewChild('addConfigurationTemplate')
  addConfigurationTemplate!: TemplateRef<any>;
  isAllOpen: boolean = true;
  public createkeysMappingForm!: UntypedFormGroup;
  responseConfigurationForm!: UntypedFormGroup;
  public submitted = false;
  public modalRef?: BsModalRef;
  projectBillingDates = [];
  keysMappingData: any;
  keysmappingValidations = Validations.keymappingForm;
  configData: any;
  payloadData: any = [];
  public editRowIndex: number | null = null;
  formType: string = '';
  itemBeingEdited: any;
  breadCrumbItems: Array<{}> | undefined;

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public payloadState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public keymapState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  totalMasterCount: number = 0;
  currentPage: number = 1;
  orderByQuery: string = '';
  filterqueryString: string = '';
  supplierOrderByQuery: string = '';
  responseConfigData: any;
  responseConfigurationFormValidations = Validations.ResponseConfigurationForm;
  constructor(
    private store: Store,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private responseService: PayloadConfigService,
    private loaderService: LoaderService
  ) {
    this.responseConfigurationForm = this.formBuilder.group({
      responseName: ['', Validators.required],  // Added default value for the form control
      projectId: ['', Validators.required],     // Added default value for the form control
      supplierId: ['', Validators.required],      // Added default value for the form control
      payload: ['', Validators.required],       // Added default value for the form control
      gatewayAddress: ['', Validators.required],// Added default value for the form control
      deviceAddress: ['', Validators.required], // Added default value for the form control
      methodUrlMappingId: ['', Validators.required], // Added default value
      methodUrl: [{ value: '', disabled: true }, Validators.required], // Moved Validators.required outside of the control config
    });
    
    this.createkeysMappingForm = this.formBuilder.group({
      id: [],
      keyDisplayName: ['', Validators.required],
      jsonkeyName: ['', Validators.required],
    });
    this.breadCrumbItems = [
      { label: 'Configurations', url: 'configuration', active: true },
      { label: 'Create Configurations' },
    ];
  }

  SaveConfig(type: string) {
    this.submitted = true;
    if (this.createkeysMappingForm.valid) {
      if (type == 'Create') {
        this.keysmappingList.push({
          id: this.generateRandomId(),
          keyDisplayName: this.createkeysMappingForm.value.keyDisplayName,
          jsonkeyName: this.createkeysMappingForm.value.jsonkeyName,
        });
        this.totalKeysMappingCount = this.keysmappingList.length;
        this.hideAddConfigurationModal();
        this.loadKeysMappings();
      } else if (type === 'Update') {
        const index = this.keysmappingList.findIndex(
          (item) => item.id === this.itemBeingEdited.id
        );
        if (index !== -1) {
          this.keysmappingList[index] = {
            ...this.keysmappingList[index],
            keyDisplayName: this.createkeysMappingForm.value.keyDisplayName,
            jsonkeyName: this.createkeysMappingForm.value.jsonkeyName,
          };
          this.hideAddConfigurationModal();
          this.loadKeysMappings();
        }
      }
      this.submitted = false;
      this.createkeysMappingForm.reset();
    }
  }

  onSortChangePayloadGrid(sort: SortDescriptor[]): void {
    this.payloadState.sort = sort;
    console.log(this.payloadState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.payloadState.sort
    );

    this.orderByQuery = orderByQuery;
    this.loadPayloadGridData();
  }

  public onMasterFilter(value: any): void {
    const inputValue = value;
  }

  protected payloadPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    this.loadPayloadGridData();
  }

  generateRandomId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  get keysControls() {
    return this.createkeysMappingForm.controls;
  }
  ngOnInit(): void {
    this.getAllConfigData();
    // this.totalKeysMappingCount = this.keysmappingList.length;
    // this.loadKeysMappings();
  }

  onSelectSupplier(supplier: any, type: string) {
    console.log(supplier);
    this.sharedService
      .getAllConfigDatawithGateWayProviderId(supplier.id)
      .subscribe((data) => {
        if (
          data.statusCode == 200 &&
          data.message == 'Config data retrieved successfully.'
        ) {
          this.configData = data.data;
          this.responseConfigurationForm.patchValue({
            methodUrlMappingId: '',
            methodUrl: '',
          });

          if (history.state.responseConfigData && type == 'Edit') {
            this.responseConfigData = JSON.parse(
              history.state.responseConfigData
            );
            this.responseConfigurationForm.patchValue({
              methodUrlMappingId:
                this.responseConfigData.responseMethodUrlMapping.id,
              methodUrl:
                this.responseConfigData.responseMethodUrlMapping.methodUrl,
            });
          } else {
            this.payloadData = [];
            this.skip = 0;
            this.pageSize = 10;
            this.sort = [];
            this.filter = { logic: 'and', filters: [] };
            this.loadPayloadGridData();
          }
        }
      });
  }

  onSelectMethod(method: any): void {
    console.log(method);

    const payload = {
      Utility: method.utility,
      gatewayProviderId: this.responseConfigurationForm.value.supplierId,
    };

    this.responseService.getPayloadConfigUrl(payload).subscribe((data) => {
      if (data.statusCode === 200) {
        this.payloadData = data.data;
        this.skip = 0;
        this.pageSize = 10;
        this.sort = [];
        this.filter = { logic: 'and', filters: [] };
        this.loadPayloadGridData();
      }
    });

    this.responseConfigurationForm.patchValue({
      methodUrl: method.methodUrl,
    });

    console.log(this.responseConfigurationForm.value);
  }

  public editHandler(rowIndex: number): void {
    this.editRowIndex = rowIndex;
  }

  public saveHandler(rowIndex: number): void {
    this.editRowIndex = null;
    this.loadPayloadGridData(); // Refresh the grid data
  }

  public cancelHandler(): void {
    this.editRowIndex = null;
  }

  loadPayloadGridData() {
    const state: State = {
      skip: this.skip,
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.payloadData, state);

    this.payloadGridData = {
      data: processedData.data,
      total: this.payloadData.length,
    };
  }
  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        if (history.state.responseConfigData) {
          this.responseConfigData = JSON.parse(
            history.state.responseConfigData
          );
          this.breadCrumbItems = [
            { label: 'Configurations', url: 'configuration', active: true },
            {
              label: this.responseConfigData
                ? 'Edit Configurations'
                : 'Create Configurations',
            },
          ];
          this.responseConfigurationForm.patchValue({
            responseName: this.responseConfigData?.responseName,
            projectId: this.responseConfigData?.projectId,
            supplierId: this.responseConfigData?.supplier.id,
            payload: this.responseConfigData?.payload,
            gatewayAddress: this.responseConfigData?.gatewayAddress,
            deviceAddress: this.responseConfigData?.deviceAddress,
            methodUrlMappingId:
              this.responseConfigData?.responseMethodUrlMapping?.id,
            methodUrl:
              this.responseConfigData?.responseMethodUrlMapping?.methodUrl,
          });
          let supplier = { id: this.responseConfigurationForm.value.supplierId };
          this.keysmappingList = this.responseConfigData?.keyMappings;
          this.loadKeysMappings();
          this.onSelectSupplier(supplier, 'Edit');
          if (this.responseConfigData?.payloadMethodUrlMappings) {
            this.payloadData =
              this.responseConfigData.payloadMethodUrlMappings.map(
                (item: any) => ({
                  id: item.payloadConfiguration?.id,
                  keyDisplayName: item.displayName,
                  utility: item.payloadConfiguration?.utility,
                  parameterName: item?.payloadConfiguration?.parameterName,
                  positionFrom: item?.payloadConfiguration?.positionFrom,
                  positionTo: item?.payloadConfiguration?.positionTo,
                })
              );
          }
          this.loadPayloadGridData();
        }
      }
    });
  }
  onFilter(data: any) {}

  private loadKeysMappings(): void {
    this.totalKeysMappingCount = this.keysmappingList.length;
    const state: State = {
      skip: this.skip,
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.keysmappingList, state);

    this.keysMappedData = {
      data: processedData.data,
      total: this.totalKeysMappingCount,
    };
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadKeysMappings();
  }

  public onPayloadFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadKeysMappings();
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadKeysMappings();
  }

  public onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadKeysMappings();
  }

  protected supplierPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.loadKeysMappings();
  }
  onSupplierFilterChange(filter: CompositeFilterDescriptor): void {
    this.keymapState.filter = filter;
    console.log(this.keymapState);
    const queryString = this.sharedService.createQuery(this.keymapState);
    this.filterqueryString = this.sharedService.createQuery(this.keymapState);
    this.loadKeysMappings();
    console.log(queryString);
  }

  onkeysSortChange(sort: SortDescriptor[]): void {
    this.keymapState.sort = sort;
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.keymapState.sort
    );

    this.supplierOrderByQuery = orderByQuery;
    this.loadKeysMappings();
  }

  protected supplierdataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadKeysMappings();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.pageSize = state.take;
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.skip = state.skip;
    this.loadKeysMappings();
  }

  public showAddConfigurationModalAndCallMethod(): void {
    this.formType = 'Add';
    this.createkeysMappingForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(this.addConfigurationTemplate);
  }

  onEdit(dataItem: any): void {
    this.itemBeingEdited = dataItem;
    this.createkeysMappingForm.patchValue(dataItem);
    this.formType = 'Edit';
    this.modalRef = this.modalService.show(this.addConfigurationTemplate);
  }
  onDelete(dataItem: any): void {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.ERROR,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        // Perform delete operation here
        this.keysmappingList = this.keysmappingList.filter(
          (item) => item.id !== dataItem.id
        );
        this.sharedService.showMessageDialog(
          'Deleted!',
          'Your row has been deleted.',
          SweetAlertIcon.SUCCESS
        );

        this.loadKeysMappings();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sharedService.showMessageDialog(
          'Cancelled',
          '',
          SweetAlertIcon.INFO
        );
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  public hideAddConfigurationModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public saveProject(): void {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'All the changes have been saved.',
      showConfirmButton: true,
    });
  }
  saveConfiguration(type: any) {
    this.submitted = true;
    if (this.responseConfigurationForm.valid) {
      const keyMappings = this.keysmappingList.map((item) => ({
        keyDisplayName: item.keyDisplayName,
        jsonkeyName: item.jsonkeyName,
      }));

      const payloadMethodUrlMappings = this.payloadData.map((payload: any) => ({
        displayName: payload.keyDisplayName,
        payloadConfigurationId: payload.id,
        methodUrlMappingId:
          this.responseConfigurationForm.value.methodUrlMappingId,
      }));

      const payload: ResponseConfiguration = {
        responseName: this.responseConfigurationForm.value.responseName,
        projectId: this.responseConfigurationForm.value.projectId,
        supplierId: this.responseConfigurationForm.value.supplierId,
        payload: this.responseConfigurationForm.value.payload,
        gatewayAddress: this.responseConfigurationForm.value.gatewayAddress,
        deviceAddress: this.responseConfigurationForm.value.deviceAddress,
        keyMappings: keyMappings,
        responseMethodUrlMapping: {
          methodUrlMappingId:
            this.responseConfigurationForm.value.methodUrlMappingId,
        },
        payloadMethodUrlMappings: payloadMethodUrlMappings, // Add your payloadMethodUrlMappings data here
      };
      if (type == 'Create') {
        console.log(payload);
        this.store.dispatch(
          createpayloadConfig({ payloadConfigData: payload })
        );
      } else {
        this.store.dispatch(
          updatepayloadConfig({
            payloadConfigData: payload,
            id: this.responseConfigData.id,
          })
        );
      }
    }
  }
}
