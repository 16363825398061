import { ResponseConfiguration } from '../../shared/modals/common';
import { PayloadConfig } from './payloadConfig.models';
import { createAction, props } from '@ngrx/store';

// responseConfigs creation

export const GetResponseConfig = createAction(
  '[responseConfig] getResponseConfig',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getResponseConfigsSuccess = createAction(
  '[responseConfig] GetResponseConfig Success',
  props<{ getresponseConfigResponse: any }>()
);
export const getResponseConfigsFailure = createAction(
  '[responseConfig] GetResponseConfig Failure',
  props<{ getresponseConfigError: any }>()
);

// Create payloadConfig
export const createpayloadConfig = createAction(
  '[responseConfig] CreatepayloadConfig',
  props<{ payloadConfigData: ResponseConfiguration }>()
);
export const CreatepayloadConfigSuccess = createAction(
  '[responseConfig] CreatepayloadConfig Success',
  props<{ createpayloadConfigResponse: any }>()
);
export const CreatepayloadConfigFailure = createAction(
  '[responseConfig] CreatepayloadConfig Failure',
  props<{ createpayloadConfigError: any }>()
);

// Update payloadConfig
export const updatepayloadConfig = createAction(
  '[responseConfig] UpdatepayloadConfig',
  props<{ payloadConfigData: ResponseConfiguration; id: string }>()
);
export const updatepayloadConfigSuccess = createAction(
  '[responseConfig] updateaster Success',
  props<{ updatepayloadConfigResponse: any }>()
);
export const updatepayloadConfigFailure = createAction(
  '[responseConfig] updatepayloadConfig Failure',
  props<{ updatepayloadConfigError: any }>()
);

//delete payloadConfig
export const deleteResponseConfigAction = createAction(
  '[responseConfig] deleteResponseConfigAction',
  props<{ id: string }>()
);
export const deleteResponseConfigSuccess = createAction(
  '[responseConfig] deleteResponseConfig Success',
  props<{ deleteResponseConfigResponse: any }>()
);
export const deleteResponseConfigFailure = createAction(
  '[responseConfig] deleteResponseConfig Failure',
  props<{ deleteResponseConfigError: any }>()
);

//all payloadconfig
export const getAllpayloadConfig = createAction(
  '[responseConfig] getallpayloadConfig',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllpayloadConfigsSuccess = createAction(
  '[responseConfig] GetallpayloadConfig Success',
  props<{ getallpayloadConfigResponse: any }>()
);
export const getAllpayloadConfigsFailure = createAction(
  '[responseConfig] GetallpayloadConfig Failure',
  props<{ getallpayloadConfigError: any }>()
);
