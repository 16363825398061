import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { IotConfigurationViewComponent } from './iot-configuration-view/iot-configuration-view.component';
import { IotCreateResponseConfigurationComponent } from './iot-create-response-configuration/iot-create-response-configuration.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { IotPayloadConfigurationComponent } from './iot-payload-configuration/iot-payload-configuration.component';

import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../shared/components/components.module';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: '',
//   maxFilesize: 50,
//   acceptedFiles: 'image/*'
// };

@NgModule({
  declarations: [
    IotConfigurationViewComponent,
    IotCreateResponseConfigurationComponent,
    IotPayloadConfigurationComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgStepperModule,
    ConfigurationRoutingModule,
    CdkStepperModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    SharedModule,
    AccordionModule,
    ComponentsModule,
  ],

  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class ConfigurationModule {}
