<div class="container-fluid">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

    <div class="row">
        <div class="col-lg-12">


            <div class="card">
                <div class="card-body">
                    <!-- <form [formGroup]="responseConfigurationForm"> -->
                    <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                        <accordion-group [isOpen]="isAllOpen" heading="Add Configuration"
                            class="accordion-item mb-3 panel-open" #item1>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                <i class="mdi font-size-24"
                                    [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>

                            <div class="row">
                                <div class="col-lg-12">
                                    <form [formGroup]="responseConfigurationForm">
                                        <div class="row">
                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Response Name <span class="required">*</span></label>
                                                    <input type="text" formControlName="responseName"
                                                        placeholder="Enter Response Name" class="form-control">
                                                        @if((submitted || responseConfigurationForm.controls['responseName'].touched)&&
                                                        responseConfigurationForm.controls['responseName'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['responseName'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.responseName.required}}</div>}
                                                        </div>}
                                                </div>
                                            </div>





                                            <div class="col-lg-4 mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Project Name  <span class="required">*</span></label>
                                                    <ng-select [items]="configData?.projects" bindLabel="name"
                                                        bindValue="id" formControlName="projectId" id="Status"
                                                        [multiple]="false"></ng-select>
                                                        @if((submitted || responseConfigurationForm.controls['projectId'].touched)&&
                                                        responseConfigurationForm.controls['projectId'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['projectId'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.projectId.required}}</div>}
                                                        </div>}

                                                </div>
                                            </div>
                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Supplier Name <span class="required">*</span></label>
                                                    <ng-select [items]="configData?.gatewayProviders" bindLabel="name"
                                                        bindValue="id" formControlName="supplierId" id="Status"
                                                        (change)="onSelectSupplier($event,'Create')"
                                                        [multiple]="false"></ng-select>
                                                        @if((submitted || responseConfigurationForm.controls['supplierId'].touched)&&
                                                        responseConfigurationForm.controls['supplierId'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['supplierId'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.supplierId.required}}</div>}
                                                        </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Payload <span class="required">*</span> </label>
                                                    <input type="text" placeholder="Enter Payload"
                                                        formControlName="payload" class="form-control">
                                                        @if((submitted || responseConfigurationForm.controls['payload'].touched)&&
                                                        responseConfigurationForm.controls['payload'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['payload'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.payload.required}}</div>}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Gateway Address  <span class="required">*</span></label>
                                                    <input type="text" formControlName="gatewayAddress"
                                                        placeholder="Enter Gateway Address" class="form-control">
                                                        @if((submitted || responseConfigurationForm.controls['gatewayAddress'].touched)&&
                                                        responseConfigurationForm.controls['gatewayAddress'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['gatewayAddress'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.gatewayAddress.required}}</div>}
                                                        </div>}
                                                </div>
                                            </div>


                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Device Address  <span class="required">*</span></label>
                                                    <input type="text" formControlName="deviceAddress"
                                                        placeholder="Enter Device Address" class="form-control">
                                                        @if((submitted || responseConfigurationForm.controls['deviceAddress'].touched)&&
                                                        responseConfigurationForm.controls['deviceAddress'].errors) {
                                                        <div class="invalid-feedback">
                                                          @if(responseConfigurationForm.controls['deviceAddress'].errors['required']) {
                                                          <div>{{responseConfigurationFormValidations.deviceAddress.required}}</div>}
                                                        </div>}
                                                </div>
                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>

                        </accordion-group>


                    </accordion>
                    <section class="mt-5">
                        <h4 class="card-title mb-4">Keys Mapping</h4>



                        <kendo-grid [data]="keysMappedData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [selectable]="true" [pageable]="{
                                            buttonCount: buttonCount,
                                            info: info,
                                            type: type,
                                            pageSizes: pageSizes,
                                            previousNext: previousNext
                                          }" [reorderable]="true" [selectable]="true" filterable="menu"
                            [filter]="keymapState.filter" [skip]="keymapState.skip" [sort]="keymapState.sort"
                            [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
                            (pageChange)="supplierPageChange($event)"
                            (dataStateChange)="supplierdataStateChange($event)"
                            (filterChange)="onSupplierFilterChange($event)" (sortChange)="onkeysSortChange($event)">

                            <ng-template kendoGridToolbarTemplate>
                                <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    (valueChange)="onFilter($event)"></kendo-textbox>
                                <kendo-grid-spacer></kendo-grid-spacer>
                                <button (click)="showAddConfigurationModalAndCallMethod()" class="btn btn-light"
                                    kendoGridAddCommand>
                                    <span class="fas fa-file-alt"></span> &nbsp; Add Configurations
                                </button>
                                <button kendoGridPDFCommand class="btn btn-light">
                                    <span class="fas fa-file-pdf"></span> &nbsp; Export to PDF
                                </button>
                                <button kendoGridExcelCommand class="btn btn-light">
                                    <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                                </button>
                            </ng-template>
                            <kendo-grid-column field="keyDisplayName" title="Key Display Name"
                                [sortable]="true"></kendo-grid-column>
                            <kendo-grid-column field="jsonkeyName" title="JSON Key Name"
                                [sortable]="true"></kendo-grid-column>
                            <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="150">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <button (click)="onEdit(dataItem)"
                                            class="btn btn-primary btn-sm d-flex align-items-center m-lg-auto">Edit</button>
                                        <button (click)="onDelete(dataItem)"
                                            class="btn btn-light btn-sm d-flex align-items-center m-lg-auto">Delete</button>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>

                        </kendo-grid>
                    </section>
                    <form [formGroup]="responseConfigurationForm">
                        <section class="row mt-5">
                            <h4 class="card-title mb-4">Method and Url Mapping</h4>

                            <div class="col-md-4 mb-3">
                                <div class="mb-3">
                                    <label class="form-label">Method <span class="required">*</span></label>
                                    <ng-select [items]="configData?.methodUrlMappings" bindLabel="methodName"
                                        bindValue="id" formControlName="methodUrlMappingId" id="Status"
                                        (change)="onSelectMethod($event)" [multiple]="false">
                                    </ng-select>
                                    @if((submitted || responseConfigurationForm.controls['methodUrlMappingId'].touched)&&
                                    responseConfigurationForm.controls['methodUrlMappingId'].errors) {
                                    <div class="invalid-feedback">
                                      @if(responseConfigurationForm.controls['methodUrlMappingId'].errors['required']) {
                                      <div>{{responseConfigurationFormValidations.methodUrlMappingId.required}}</div>}
                                    </div>}
                                </div>
                            </div>

                            <div class="col-md-8 mb-3">
                                <div class="mb-3">
                                    <label class="form-label">Url<span class="required">*</span></label>
                                    <input type="text" class="form-control" [disabled]="true"
                                        formControlName="methodUrl" id="methodUrl" placeholder="Enter URL">
                                        @if((submitted || responseConfigurationForm.controls['methodUrl'].touched)&&
                                        responseConfigurationForm.controls['methodUrl'].errors) {
                                        <div class="invalid-feedback">
                                          @if(responseConfigurationForm.controls['methodUrl'].errors['required']) {
                                          <div>{{responseConfigurationFormValidations.methodUrl.required}}</div>}
                                        </div>}
                                </div>
                            </div>
                        </section>

                    </form>

                    <section class="mt-5">
                        <h4 class="card-title mb-4">Payload Configuration</h4>



                        <kendo-grid [data]="payloadGridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [groupable]="false" [selectable]="true" [pageable]="{
                                                                buttonCount: buttonCount,
                                                                info: info,
                                                                type: type,
                                                                pageSizes: pageSizes,
                                                                previousNext: previousNext
                                                              }" [reorderable]="true" filterable="menu"
                            [filter]="payloadState.filter" [skip]="payloadState.skip" [sort]="payloadState.sort"
                            [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id" scrollable="none"
                            (filterChange)="onPayloadFilterChange($event)"
                            (sortChange)="onSortChangePayloadGrid($event)" (pageChange)="payloadPageChange($event)"
                            (dataStateChange)="dataStateChange($event)">

                            <ng-template kendoGridToolbarTemplate>
                                <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    (valueChange)="onFilter($event)"></kendo-textbox>
                                <kendo-grid-spacer></kendo-grid-spacer>

                                <button kendoGridPDFCommand class="btn btn-light">
                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button>
                                <button kendoGridExcelCommand class="btn btn-light">
                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                </button>

                            </ng-template>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                [width]="40"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="keyDisplayName" title="Payload Display Name">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <ng-container *ngIf="editRowIndex !== rowIndex; else editTemplate">
                                        {{ dataItem.keyDisplayName }}
                                    </ng-container>
                                    <ng-template #editTemplate>
                                        <input [(ngModel)]="dataItem.keyDisplayName" class="k-textbox" />
                                    </ng-template>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="parameterName" title="Payload Name"></kendo-grid-column>
                            <kendo-grid-column field="positionFrom" title="position From"></kendo-grid-column>
                            <kendo-grid-column field="positionTo" title="position To"></kendo-grid-column>

                            <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="150">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <div class="d-flex">
                                        <button class="btn btn-primary btn-sm d-flex align-items-center m-lg-auto"
                                            *ngIf="editRowIndex !== rowIndex" (click)="editHandler(rowIndex)">
                                            Edit
                                        </button>
                                        <button class="btn btn-success btn-sm d-flex align-items-center m-lg-auto"
                                            *ngIf="editRowIndex === rowIndex" (click)="saveHandler(rowIndex)">
                                            Save
                                        </button>
                                        <button class="btn btn-secondary btn-sm d-flex align-items-center m-lg-auto"
                                            *ngIf="editRowIndex === rowIndex" (click)="cancelHandler()">
                                            Cancel
                                        </button>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>


                        </kendo-grid>


                    </section>


                    <!-- </form> -->

                    <!-- end card body -->
                    <ul class="list-inline wizard d-flex justify-content-end mb-0 mt-4">
                        <li class="next list-inline-item">
                            <button class="btn btn-primary" (click)="saveConfiguration(responseConfigData?
                                'Update' : 'Create')">{{responseConfigData?
                                'Update' : 'Save'}}
                            </button>
                        </li>
                    </ul>
                </div>

                <!-- end card -->
            </div>

            <!-- end col -->
        </div>

    </div>

    <!-- Add Configuration modal -->
    <ng-template #addConfigurationTemplate>
        <div class="modal-content">
            <div class="modal-header bg-primary text-white">
                <h5 class="modal-title">{{formType}} Configuration</h5>
                <button type="button" class="btn-close text-white" aria-label="Close"
                    (click)="hideAddConfigurationModal()"></button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" [formGroup]="createkeysMappingForm">
                    <div class="mb-3">
                        <label>Key Display Name<span class="required">*</span></label>
                        <input type="text" placeholder="Enter Key Display Name" formControlName="keyDisplayName"
                            id="keyDisplayName" class="form-control">
                        @if(submitted && keysControls['keyDisplayName'].errors){
                        <div class="invalid-feedback">
                            @if(keysControls['keyDisplayName'].errors['required']){
                            <div>{{keysmappingValidations.keyDisplayName.required}}</div>}

                        </div>}
                    </div>
                    <div class="mb-3">
                        <label>Json Key Name<span class="required">*</span></label>
                        <input type="text" placeholder="Enter Json Key Name" formControlName="jsonkeyName"
                            id="jsonkeyName" class="form-control">
                        @if(submitted && keysControls['jsonkeyName'].errors){
                        <div class="invalid-feedback">
                            @if(keysControls['jsonkeyName'].errors['required']){
                            <div>{{keysmappingValidations.jsonkeyName.required}}</div>}

                        </div>}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="formType=='Edit'; else createButton">
                    <button type="button" class="btn btn-primary waves-effect waves-light"
                        (click)="SaveConfig('Update')">Update</button>
                </ng-container>
                <ng-template #createButton>
                    <button type="button" class="btn btn-primary waves-effect waves-light"
                        (click)="SaveConfig('Create')">Save
                        </button>
                </ng-template>
            </div>
        </div>
    </ng-template>

    <!-- Add Configuration modal End  -->