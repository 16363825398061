<div class="container-fluid">

    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">Configuration</h4>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <div id="basic-example">
                        <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper">

                            <cdk-step [optional]="false">
                                <ng-template cdkStepLabel>
                                    <span class="number">1.</span>
                                    <span>Response Configurations</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 0){

                                <section class="">
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>

                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <kendo-grid [data]="responseConfigGridData" [pageSize]="responseConfigPageSize"
                                            [skip]="skip" [sortable]="true" [groupable]="false"
                                            [selectable]="selectableSettings" [pageable]="{
                                                              buttonCount: buttonCount,
                                                              info: info,
                                                              type: type,
                                                              pageSizes: pageSizes,
                                                              previousNext: previousNext
                                                            }" [reorderable]="true" filterable="menu"
                                            [filter]="payloadConfigState.filter" [skip]="payloadConfigState.skip"
                                            [sort]="payloadConfigState.sort" [columnMenu]="{ filter: true }"
                                            [resizable]="true" kendoGridSelectBy="id" scrollable="none"
                                            (filterChange)="onPayloadConfigFilterChange($event)"
                                            (sortChange)="onSortChange($event,'master')"
                                            (pageChange)="payloadConfigPageChange($event)"
                                            (selectionChange)="selectionChanged($event)"
                                            (dataStateChange)="dataStateChange($event)">


                                            <ng-template kendoGridToolbarTemplate>
                                                <div>
                                                    <kendo-textbox [style.width.px]="250"
                                                        [(ngModel)]="searchedPayloadConfigValue"  [clearButton]="true"
                                                        (valueChange)="clearSearchValueChangeOnOffData($event)"
                                                        placeholder="Search in all columns...">
                                                    </kendo-textbox>

                                                    <button class="btn btn-primary btn-md border-left-none"                                                      
                                                        (click)="onFilter($event)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>
                                                <button (click)="createResponseConfig()" class="btn btn-light"
                                                    kendoGridAddCommand>
                                                    <span class="fas fa-file-alt"> </span> &nbsp; Add Configurations
                                                </button>
                                                <!-- <button kendoGridPDFCommand class="btn btn-light">
                                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                                                <button kendoGridExcelCommand class="btn btn-light"
                                                    (click)="onExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>

                                            </ng-template>

                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                [width]="40" class="no-tooltip"></kendo-grid-checkbox-column>
                                            <kendo-grid-column field="responseName"
                                                title="Response Name"></kendo-grid-column>
                                            <kendo-grid-column field="projectName"
                                                title="project Name"></kendo-grid-column>
                                            <kendo-grid-column field="supplier.name"
                                                title="Supplier Name"></kendo-grid-column>
                                            <kendo-grid-column field="payload" title="payload"></kendo-grid-column>
                                            <kendo-grid-column field="gatewayAddress"
                                                title="Gateway Address"></kendo-grid-column>
                                            <kendo-grid-column field="deviceAddress"
                                                title="Device Address"></kendo-grid-column>
                                            <!-- <kendo-grid-column field="ProjectStatus"
                                            title="Gateway Address"></kendo-grid-column> -->
                                            <!-- <kendo-grid-command-column  [columnMenu]="false"
                                                [width]="150">

                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="d-flex">
                                                        <button (click)="editResponseConfig(dataItem)"
                                                            class="btn btn-primary btn-sm d-flex align-items-center m-lg-auto ">
                                                            Edit
                                                        </button>
                                                        <button (click)="deleteResponse(dataItem)"
                                                            class="btn btn-light btn-sm d-flex align-items-center m-lg-auto ">
                                                            Delete
                                                        </button>
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-command-column> -->
                                            <kendo-grid-command-column  [columnMenu]="false" [width]="150">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                  <div class="d-flex">
                                                    <button tooltip="Go to Master Edit"
                                                      class="btn btn-primary btn-sm d-flex align-items-center " (click)="editResponseConfig(dataItem)">
                                                      <i class="fas fa-edit"></i>
                                                    </button>
                                                    <button tooltip="Delete Master"
                                                      class="btn btn-danger btn-sm d-flex align-items-center m-lg-auto ms-3 p-2"
                                                      (click)="deleteResponse(dataItem)">
                                                      <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                  </div>
                                                </ng-template>
                                              </kendo-grid-command-column>

                                        </kendo-grid>

                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="mySelectionPayloadConfig.length>0 ? mySelectionPayloadConfig:allPayloadConfigData.length>0 ? allPayloadConfigData:responseConfigGridData.data"
                                                #pdfGrid>
                                                <kendo-grid-column field="responseName"
                                                    title="Response Name"></kendo-grid-column>
                                                <kendo-grid-column field="project.name"
                                                    title="project Name"></kendo-grid-column>
                                                <kendo-grid-column field="master.name"
                                                    title="Supplier Name"></kendo-grid-column>
                                                <kendo-grid-column field="payload" title="payload"></kendo-grid-column>
                                                <kendo-grid-column field="gatewayAddress"
                                                    title="Gateway Address"></kendo-grid-column>
                                                <kendo-grid-column field="deviceAddress"
                                                    title="Device Address"></kendo-grid-column>
                                                <kendo-grid-excel fileName="ResponseConfig.xlsx"></kendo-grid-excel>
                                            </kendo-grid>

                                        </div>
                                    </div>
                                </section>


                                }
                            </cdk-step>

                            <!-- <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">2.</span>
                                    <span>Payload Configurations</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 1){
                                <section>
                                    <kendo-grid [data]="payloadConfigGridData" [pageSize]="payloadConfigPageSize"
                                        [skip]="skip" [sortable]="true" [groupable]="false"
                                        [selectable]="selectableSettings" [pageable]="{
                                                              buttonCount: buttonCount,
                                                              info: info,
                                                              type: type,
                                                              pageSizes: pageSizes,
                                                              previousNext: previousNext
                                                            }" [reorderable]="true" filterable="menu"
                                        [filter]="payloadConfigState.filter" [skip]="payloadConfigState.skip"
                                        [sort]="payloadConfigState.sort" [columnMenu]="{ filter: true }"
                                        [resizable]="true" kendoGridSelectBy="id" scrollable="none"
                                        (filterChange)="onPayloadConfigFilterChange($event)"
                                        (sortChange)="onSortChange($event,'master')"
                                        (pageChange)="payloadConfigPageChange($event)"
                                        (selectionChange)="selectionChanged($event)"
                                        (dataStateChange)="dataStateChange($event)">

                                        <ng-template kendoGridToolbarTemplate>
                                            <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                                (valueChange)="onFilter($event)"></kendo-textbox>

                                            <kendo-grid-spacer></kendo-grid-spacer>
                                            <button (click)="createPayloadConfig()" class="btn btn-light"
                                                kendoGridAddCommand>
                                                <span class="fas fa-file-alt"> </span> &nbsp; Add Payload Configurations
                                            </button>
                                            <button class="btn btn-light" (click)="export('Pdf')">
                                                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF
                                            </button>
                                            <button class="btn btn-light" (click)="export('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>
                                        </ng-template>

                                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                            [width]="40"></kendo-grid-checkbox-column>
                                        <kendo-grid-column field="payloadName"
                                            title="Response Name"></kendo-grid-column>
                                        <kendo-grid-column field="parameterName"
                                            title="Parameter Name"></kendo-grid-column>
                                        <kendo-grid-column field="positionFrom"
                                            title="Position From"></kendo-grid-column>
                                        <kendo-grid-column field="positionTo" title="Position To"></kendo-grid-column>
                                        <kendo-grid-column field="dataType" title="Data Type"></kendo-grid-column>
                                        <kendo-grid-command-column  [columnMenu]="false" [width]="150">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="d-flex">
                                                    <button (click)="editPayloadConfig(dataItem)" class="btn btn-primary btn-sm d-flex align-items-center
                                                    m-lg-auto ">
                                                        Edit
                                                    </button>
                                                    <button (click)="deletePayloadConfig(dataItem)"
                                                        class="btn btn-light btn-sm d-flex align-items-center m-lg-auto ">
                                                        Delete
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </kendo-grid-command-column>

                                    </kendo-grid>

                                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">



                                        <kendo-grid
                                            [kendoGridBinding]="mySelectionPayloadConfig.length>0 ? mySelectionPayloadConfig:allPayloadConfigData.length>0?allPayloadConfigData:payloadConfigGridData.data"
                                            #payloadconfigGrid>
                                            <kendo-grid-column field="payloadName"
                                                title="Response Name"></kendo-grid-column>
                                            <kendo-grid-column field="parameterName"
                                                title="Parameter Name"></kendo-grid-column>
                                            <kendo-grid-column field="positionFrom"
                                                title="Position From"></kendo-grid-column>
                                            <kendo-grid-column field="positionTo"
                                                title="Position To"></kendo-grid-column>
                                            <kendo-grid-column field="dataType" title="Data Type"></kendo-grid-column>
                                            <kendo-grid-excel fileName="payloadConfig.xlsx"></kendo-grid-excel>
                                            <kendo-grid-pdf fileName="payloadConfig.pdf" [allPages]="true"
                                                paperSize="A4" [landscape]="true"></kendo-grid-pdf>
                                        </kendo-grid>
                                    </div>
                                </section>

                                }
                            </cdk-step> -->

                        </ng-stepper>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>