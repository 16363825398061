<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">Add Payload Configurations</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item active" [routerLink]="'/configuration'"><a
                                href="javascript: void(0);">Configurations</a>
                        </li>

                        <li class="breadcrumb-item">Payload Configurations
                        </li>

                    </ol>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">


            <div class="card">
                <div class="card-body">

                    <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                        <accordion-group [isOpen]="isAllOpen" heading="Add Payload Configuration"
                            class="accordion-item mb-3 panel-open" #item1>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                <i class="mdi font-size-24"
                                    [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>

                            <div class="row">
                                <div class="col-lg-12">
                                    <form [formGroup]="payloadConfigForum">
                                        <div class="row">
                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Payload Name</label>
                                                    <input type="text" formControlName="payloadName"
                                                        placeholder="Enter Response Name" class="form-control">
                                                    @if(submitted && payloadConfigForum.controls['payloadName'].errors)
                                                    {
                                                    <div class="invalid-feedback">
                                                        @if(payloadConfigForum.controls['payloadName'].errors['required'])
                                                        {
                                                        <div>{{payloadConfig_Validations.payloadName.required}}</div>}
                                                        @if(payloadConfigForum.controls['payloadName'].errors['minlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.payloadName.minLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['payloadName'].errors['maxlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.payloadName.MaxLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['payloadName'].errors['pattern'])
                                                        {
                                                        <div>{{payloadConfig_Validations.payloadName.patternValidation}}
                                                        </div>}
                                                    </div>}
                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Parameter Name </label>
                                                    <input type="text" formControlName="parameterName"
                                                        placeholder="Enter Parameter Name" class="form-control">
                                                    @if(submitted &&
                                                    payloadConfigForum.controls['parameterName'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(payloadConfigForum.controls['parameterName'].errors['required'])
                                                        {
                                                        <div>{{payloadConfig_Validations.parameterName.required}}</div>}
                                                        @if(payloadConfigForum.controls['parameterName'].errors['minlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.parameterName.minLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['parameterName'].errors['maxlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.parameterName.MaxLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['parameterName'].errors['pattern'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.parameterName.patternValidation}}
                                                        </div>}
                                                    </div>}
                                                </div>
                                            </div>


                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Position From</label>
                                                    <input type="text" formControlName="positionFrom"
                                                        placeholder="Enter Position From " class="form-control">
                                                    @if(submitted &&
                                                    payloadConfigForum.controls['positionFrom'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(payloadConfigForum.controls['positionFrom'].errors['required'])
                                                        {
                                                        <div>{{payloadConfig_Validations.positionFrom.required}}</div>}
                                                        @if(payloadConfigForum.controls['positionFrom'].errors['minlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionFrom.minLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['positionFrom'].errors['maxlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionFrom.MaxLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['positionFrom'].errors['pattern'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionFrom.patternValidation}}
                                                        </div>}
                                                    </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Position To </label>
                                                    <input type="text" formControlName="positionTo"
                                                        placeholder="Enter Position To" class="form-control">
                                                    @if(submitted &&
                                                    payloadConfigForum.controls['positionTo'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(payloadConfigForum.controls['positionTo'].errors['required'])
                                                        {
                                                        <div>{{payloadConfig_Validations.positionTo.required}}</div>}
                                                        @if(payloadConfigForum.controls['positionTo'].errors['minlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionTo.minLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['positionTo'].errors['maxlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionTo.MaxLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['positionTo'].errors['pattern'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.positionTo.patternValidation}}
                                                        </div>}
                                                    </div>}

                                                </div>
                                            </div>




                                            <div class="col-lg-4 mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Data Type</label>
                                                    <input type="text" formControlName="dataType"
                                                        placeholder="Enter Data Type" class="form-control">
                                                    @if(submitted &&
                                                    payloadConfigForum.controls['dataType'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(payloadConfigForum.controls['dataType'].errors['required'])
                                                        {
                                                        <div>{{payloadConfig_Validations.dataType.required}}</div>}
                                                        @if(payloadConfigForum.controls['dataType'].errors['minlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.dataType.minLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['dataType'].errors['maxlength'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.dataType.MaxLengthValidator}}
                                                        </div>}
                                                        @if(payloadConfigForum.controls['dataType'].errors['pattern'])
                                                        {
                                                        <div>
                                                            {{payloadConfig_Validations.dataType.patternValidation}}
                                                        </div>}
                                                    </div>}

                                                </div>
                                            </div>


                                        </div>

                                    </form>
                                    <div class="modal-footer">
                                        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                                        @if(payloadConfigData?.payloadName){ <button type="button"
                                            class="btn btn-primary waves-effect waves-light"
                                            (click)="SavePayloadConfig('Update')">Update </button>}
                                        @else{ <button type="button" class="btn btn-primary waves-effect waves-light"
                                            (click)="SavePayloadConfig('Create')">Save</button> }
                                    </div>
                                </div>
                            </div>

                        </accordion-group>


                    </accordion>



                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>

</div>