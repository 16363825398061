import { createFeatureSelector, createSelector } from '@ngrx/store';
import { payloadConfigState } from './payloadConfig.reducer';

export const getPayloadConfigState =
  createFeatureSelector<payloadConfigState>('payloadConfig');

export const getResponseConfigResponse = createSelector(
  getPayloadConfigState,
  (state: payloadConfigState) => state.getresponseConfigResponse
);

export const createPayloadResponse = createSelector(
  getPayloadConfigState,
  (state: payloadConfigState) => state.createpayloadConfigResponse
);

export const deleteResponseConfig = createSelector(
  getPayloadConfigState,
  (state: payloadConfigState) => state.deleteResponseConfigResponse
);

export const allPayloadResponse = createSelector(
  getPayloadConfigState,
  (state: payloadConfigState) => state.getallpayloadConfigResponse
);
