import { ResponseConfiguration, utility } from './../../shared/modals/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Master } from '../../store/Masters/masters.models';
import { PayloadConfig } from '../../store/payload_Config/payloadConfig.models';

@Injectable({ providedIn: 'root' })
export class PayloadConfigService {
  constructor(private http: HttpClient, private router: Router) {}

  getResponseConfig(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.getResponseConfigUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      (searchTerm ? `&search=${searchTerm}` : '') +
      (filter ? `&${filter}` : '') +
      (orderBy ? `&${orderBy}` : '');
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createResponseConfig(payloadconfig: ResponseConfiguration): Observable<any> {
    return this.http
      .post<any>(AppConfigData.createResponseConfigUrl, payloadconfig)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  getPayloadConfig(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.getPayloadConfigUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      (searchTerm ? `&search=${searchTerm}` : '') +
      (filter ? `&${filter}` : '') +
      (orderBy ? `&${orderBy}` : '');
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  updatePayloadConfig(
    payloadconfig: ResponseConfiguration,
    id: String
  ): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updatePayloadConfigUrl + id, payloadconfig)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteResponseConfig(id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.deleteResponseConfigUrl + id, null)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('deleteFaqs failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  getPayloadConfigUrl(payload?: any): Observable<any> {
    let url = `${AppConfigData.getPayloadConfigUrl}?Utility=${payload.Utility}&gatewayProviderId=${payload.gatewayProviderId}`;

    return this.http.get<any>(url).pipe(
      map((data: any) => {
        return data;
        console.log('Fetched Config Data:', data);
      })
    );
  }
}
