export const projectData = [
    { ProjectCode: 'RJ1920212120024', ReceiverTime:'', ProjectName: 'Yojith', Region: 'Nagaland', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active',},
    { ProjectCode: 'RJ1920212120023', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Tripura', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120022', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Mizoram', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120021', ReceiverTime:'', ProjectName: 'Hridaan', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120020', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120019', ReceiverTime:'', ProjectName: 'Yojith', Region: 'Tripura', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120018', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120017', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Tripura', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120016', ReceiverTime:'', ProjectName: 'Hridaan', Region: 'Mizoram', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120015', ReceiverTime:'', ProjectName: 'Bhavin', Region: 'Nagaland', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120014', ReceiverTime:'', ProjectName: 'Hridaan', Region: 'Nagaland', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120013', ReceiverTime:'', ProjectName: 'Yojith', Region: 'Tripura', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    
];